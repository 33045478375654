import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SubspaceProvider } from "react-redux-subspace";
import InsightsTemplate from "../insights-template";
import styles from "../insights-template/insights-template.scss";
import { changeSkusSorting } from "../../containers/insights/insights-skus/actions";
import { setCurrentViewIsUpdated } from "../../containers/insights/actions";

import {
  convertConfigSortFieldToBrowserString,
  convertBrowserStringSortFieldToConfig,
} from "../../utils/skus/convertSortValue";

import { BRAND_PERFORMANCE_VIEW_TYPE_DATA } from "../../containers/brand-performance/constants";
import BrandPerformanceInfiniteTable from "../../containers/brand-performance/components/table/brand-performance-infinite-table";
import BrandPerformanceService from "../../services/brandPerformanceService";
import BetaTag from "../../components/beta-tag/beta-tag";
const brandPerformanceService = new BrandPerformanceService();

const BrandPerformance = (props) => {
  const { selectedDateRange, lastProcessedDay } = props;

  const viewTypeData = BRAND_PERFORMANCE_VIEW_TYPE_DATA;
  const search = props.location.search;

  const TitleComponent = () => {
    return <div style={{ display: "flex" }}>Brand Performance</div>;
  };

  const dataControlsOptions = {
    viewTypeData,
    lastAvailableDate: lastProcessedDay ?? null,
    maxDate: lastProcessedDay ? new Date(lastProcessedDay) : null,
    apiProvider: brandPerformanceService,
    dateRange: selectedDateRange,
    hideCompareDates: false,
    handlePresetClick: null,
    hideFrequency: true,
    mbFilter: true,
    convertConfigSortFieldToBrowserString: convertConfigSortFieldToBrowserString,
    minimizeInsightsFilterByDefault: true,
    showEventTriggers: false,
    addDatesToUrl: true,
    hideExport: true,
    TitleComponent: TitleComponent,
  };

  const body = (
    <>
      <div className={styles.wrapperFill}>
        <BrandPerformanceInfiniteTable />
      </div>
    </>
  );

  return (
    <SubspaceProvider
      mapState={(state) => state.brandPerformance}
      namespace={"brandPerformance"}
    >
      <InsightsTemplate
        body={body}
        dataControlsOptions={dataControlsOptions}
        lastAvailableDate={lastProcessedDay ? new Date(lastProcessedDay) : null}
        search={search}
        viewTypeData={viewTypeData}
        apiProvider={brandPerformanceService}
        convertBrowserStringSortFieldToConfig={
          convertBrowserStringSortFieldToConfig
        }
        hideInsightsFilter={false}
        TitleComponent
      />
    </SubspaceProvider>
  );
};

export const mapStateToProps = (state) => {
  const insights = state.brandPerformance.insights.insights;
  const lastProcessedDay =
    insights.viewDefinitions?.viewMetadata?.lastProcessedDay;
  return {
    selectedDateRange:
      state.brandPerformance.insights.mbFilterRow.selectedDateRange,
    currentViewConfigQuery: insights.currentViewConfig.query,
    lastProcessedDay,
    customerRootDomain: state.brandPerformance.user.customerInfo.rootDomain,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeSkusSorting,
      setCurrentViewIsUpdated,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BrandPerformance);
